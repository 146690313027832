/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "ap-northeast-1",
//     "aws_cognito_identity_pool_id": "ap-northeast-1:1f2db46d-1f49-4ce8-9823-f45e432c1699",
//     "aws_cognito_region": "ap-northeast-1",
//     "aws_user_pools_id": "ap-northeast-1_K4Wl28r2U",
//     "aws_user_pools_web_client_id": "7fu58na2ocquoqauoqrlfe12j2",
//     "oauth": {},
//     "aws_dynamodb_all_tables_region": "ap-northeast-1",
//     "aws_dynamodb_table_schemas": [
//         {
//             "tableName": "facility_info_tbl-dev",
//             "region": "ap-northeast-1"
//         },
//         {
//             "tableName": "congestion_info_tbl-dev",
//             "region": "ap-northeast-1"
//         }
//     ],
//     "aws_cloud_logic_custom": [
//         {
//             "name": "iotsstmdevtest201211",
//             "endpoint": "https://6ssqgztqqf.execute-api.ap-northeast-1.amazonaws.com/dev",
//             "region": "ap-northeast-1"
//         }
//     ],
//     "aws_content_delivery_bucket": "iotssdevtest201211-hostingbucket-dev",
//     "aws_content_delivery_bucket_region": "ap-northeast-1",
//     "aws_content_delivery_url": "http://iotssdevtest201211-hostingbucket-dev.s3-website-ap-northeast-1.amazonaws.com"
// };

const awsmobile = {
    "aws_project_region": process.env.VUE_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.VUE_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_COGNITO_WEB_CLIENT_ID,
    "oauth": {},
    "aws_dynamodb_all_tables_region": process.env.VUE_APP_AWS_DYNAMODB_TABLE_REGION,
    "aws_dynamodb_table_schemas": [
        {
            "tableName": process.env.VUE_APP_AWS_DYNAMODB_FACILITY_TABLE_NAME,
            "region": process.env.VUE_APP_AWS_DYNAMODB_TABLE_REGION
        },
        {
            "tableName": process.env.VUE_APP_AWS_DYNAMODB_CONGESTION_TABLE_NAME,
            "region": process.env.VUE_APP_AWS_DYNAMODB_TABLE_REGION
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "iotsstmdevtest201211",
            "endpoint": process.env.VUE_APP_AWS_API_BASE_URL,
            "region": process.env.VUE_APP_AWS_API_REGION
        }
    ],
    "aws_content_delivery_bucket": process.env.VUE_APP_AWS_CONTEENT_DELIVERY_BUKET,
    "aws_content_delivery_bucket_region": process.env.VUE_APP_AWS_CONTEENT_DELIVERY_BUKET_REGION,
    "aws_content_delivery_url": process.env.VUE_APP_AWS_CONTEENT_DELIVERY_URL
};


export default awsmobile;
